:root {
    --body-bg-color:#f5f7fa;
    --primary-color:#051C2C;
  }
.authFormContainer {
    background: white url('../../../assets/img/base-bg.jpg') no-repeat;
    width: 100%;
    height: 100%;
    padding:30px;
    /* position: absolute; */
}
.bg-design {
    background: white url('../../../assets/img/base-bg.jpg') no-repeat center top;
}


